import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class PricingOptionsService {

  currentlySolvingFor: Number;
  solvingForChange: Subject<Number> = new Subject<Number>();
  commitSolve: boolean = true;
  enableVolShiftOnMetrics = false;
  useLambda = environment.lambda_default;

  constructor() {
    this.currentlySolvingFor = 0;
  }

  public solvingFor(id: Number) {
    this.currentlySolvingFor = id;
    this.solvingForChange.next(id);
  }

  public toggleCommitSolve() {
    this.commitSolve = !this.commitSolve;
  }

  public toggleEnableVolShiftOnMetrics() {
    this.enableVolShiftOnMetrics = !this.enableVolShiftOnMetrics;
  }

  public toggleUseLambda() {
    this.useLambda = !this.useLambda;
  }

}
