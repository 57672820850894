import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MatCardModule} from '@angular/material/card';
import { Router } from '@angular/router';
import { OptionBoxComponent } from '../../dashboard/option-box/option-box.component';
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss'
})
export class DashboardComponent {

  constructor(private router:Router){
  }

  public navigate(): void {
    this.router.navigate([environment.v2url])
  }

  options = [
    {
      name: 'Build',
      path: 'https://build.arrow-qa.ljstechnology.co.uk/product-builder', //unused
      description: 'Structure, evaluate, and analyze a new product or portfolio',
      iconPath: '/assets/icons/ARROW_Icon_BUILD_Passive.png',
      isV2: true
    },
    {
      name: 'Review',
      path: 'review',
      description: 'Evaluate and analyze an existing product or portfolio',
      iconPath: '/assets/icons/ARROW_Icon_REVIEW_Passive.png',
      isV2: false
    },
    {
      name: 'Trade',
      path: 'trade',
      description: 'Fix and evaluate final terms for a product striking today',
      iconPath: '/assets/icons/ARROW_Icon_TRADE_Passive.png',
      isV2: false
    },
    {
      name: 'Markets',
      path: 'market',
      description: 'View market data that is specifically relevant to Structured Investment valuations',
      iconPath: '/assets/icons/ARROW_Icon_MARKETS_Passive.png',
      isV2: false
    }
  ];

}
