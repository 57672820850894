<div class="container full-screen-container">
    <div class="row full-screen-container">
        <div class="col-md-8 inputs-section">
            <div class="title-row">
                <div class="col-md-6">
                  <h2 class="page-header">Build and Price</h2>
                </div>
                <div class="col-md-6">
                  <div class="col-md-6 btn" *ngIf="this.shouldShowRestoreLastSessionButton()">
                    <button class="col-md-12 border-btn calculate-btn" mat-raised-button (click)="restoreSavedSession()">
                      RESTORE LAST SESSION
                    </button>
                  </div>
                  <div class="col-md-6 btn">
                    <button class="col-md-12 border-btn calculate-btn" mat-raised-button
                            (click)="viewLoadProductDialog()">LOAD
                    </button>
                  </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 inputs-left">
                    <div class="row">
                        <div class="col-md-12">
                            <mat-card>
                                <mat-card-header>Structure & Issuer</mat-card-header>
                                <mat-card-content>
                                    <div class="row">
                                        <div class="col-md-8 label-hover">
                                            <mat-label class="label">PICK A TEMPLATE</mat-label>
                                            <mat-form-field class="col-md-12">
                                                <!-- <mat-label>PICK A TEMPLATE</mat-label> -->
                                                <mat-select [(value)]="productBuildDetails.shape" (selectionChange)="templateChange($event)">
                                                    @for (shape of shapes; track shape) {
                                                        <mat-option [value]="shape.value">{{shape.name}}</mat-option>
                                                    }
                                                </mat-select>
                                                <mat-icon matSuffix>expand_more</mat-icon>
                                            </mat-form-field>
                                        </div>
                                        <div class="col-md-4 label-hover">
                                            <mat-label class="label">ISSUER</mat-label>
                                            <mat-form-field class="col-md-12">
                                                <input id="issuer-input" onclick="this.select()" matInput #issuerInput [class.has-stale-issuer]="staleIssuer" [matAutocomplete]="auto" [formControl]="issuerCtrl">
                                                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedIssuer($event)">
                                                    @for (p of filteredIssuers | async; track p) {
                                                        <mat-option [value]="p">{{p.name}}</mat-option>
                                                    }
                                                </mat-autocomplete>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </mat-card-content>
                            </mat-card>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <mat-card>
                                <mat-card-header>Underlying & Currency</mat-card-header>
                                <mat-card-content>
                                    <div class="row">
                                        <div class="col-md-4 label-hover">
                                            <mat-label  class="label">CURRENCY</mat-label>
                                            <mat-form-field class="dont-fill-height col-md-12">
                                                <mat-select [(value)]="productBuildDetails.ccy">
                                                    @for (ccy of ccys; track ccy) {
                                                        <mat-option [value]="ccy">{{ccy}}</mat-option>
                                                    }
                                                </mat-select>
                                                <mat-icon matSuffix>expand_more</mat-icon>
                                            </mat-form-field>
                                        </div>
                                        <div class="col-md-8 label-hover underlying-input">
                                            <mat-label class="label">UNDERLYINGS</mat-label>
                                            <mat-form-field class="col-md-12" *ngIf="!this.productBuildDetails.isBasket">
                                                <mat-chip-grid #chipGrid aria-label="Undlerying selection">
                                                @for (u of productBuildDetails.underlyings; track u) {
                                                    <mat-chip-row [class.has-error]="hasStaleMarketData(u)" (removed)="remove(u)">
                                                    {{u}}
                                                    <button matChipRemove [attr.aria-label]="'remove ' + u">
                                                        <mat-icon>cancel</mat-icon>
                                                    </button>
                                                    </mat-chip-row>
                                                }
                                                </mat-chip-grid>
                                                <input #underlyingInput [formControl]="underlyingCtrl"
                                                [matChipInputFor]="chipGrid" [matAutocomplete]="auto"
                                                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                                (matChipInputTokenEnd)="add($event)"/>
                                                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
                                                @for (u of filteredUnderlyings | async; track u) {
                                                    <mat-option [value]="u">{{u}}</mat-option>
                                                }
                                                </mat-autocomplete>
                                            </mat-form-field>
                                            <div class="col-md-12 date-btn" *ngIf="this.productBuildDetails.isBasket">
                                                <button class="col-md-12 btn-with-icon-suffix" mat-raised-button (click)="viewUnderlyingInput()"><mat-icon iconPositionEnd>chevron_right</mat-icon>BASKET</button>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="col-md-4 put-type" *ngIf="this.productBuildDetails.underlyings.length > 1">
                                        <mat-chip-listbox (change)="basketTypeChange($event)" aria-label="Basket Type">
                                            <mat-chip-option value="0" [selected]="!this.productBuildDetails.isBasket">Worst of</mat-chip-option>
                                            <mat-chip-option value="1" [selected]="this.productBuildDetails.isBasket">Basket</mat-chip-option>
                                        </mat-chip-listbox>
                                    </div>
                                </mat-card-content>
                            </mat-card>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <mat-card>
                                <mat-card-header>Principle Exposure</mat-card-header>
                                <mat-card-content>
                                    <div>
                                        <app-principle-exposure [putDetails]="productBuildDetails.principleExposure" (principleExposureChanged)="principleExposureChange($event)"></app-principle-exposure>
                                    </div>
                                </mat-card-content>
                            </mat-card>
                        </div>
                    </div>
                    <div class="row" *ngIf="productBuildDetails.shape==0">
                        <div class="col-md-12">
                            <mat-card>
                                <mat-card-header>Call Premium</mat-card-header>
                                <mat-card-content>
                                    <app-call-premium-inputs [frequency]="productBuildDetails.frequency" [(autocallOptions)]="productBuildDetails.autocallOptions" (somethingChanged)="autoCallPremiumInputsHaveChanged()"></app-call-premium-inputs>
                                </mat-card-content>
                            </mat-card>
                        </div>
                    </div>
                    <div class="row" *ngIf="productBuildDetails.shape==1">
                        <div class="col-md-12">
                            <mat-card>
                                <mat-card-header>Coupons</mat-card-header>
                                <mat-card-content>
                                    <app-coupon-inputs [frequencyDefault]="productBuildDetails.frequency" [coupons]="productBuildDetails.couponOptions!" (updatedCouponOptions)="couponOptionsChanged($event)"></app-coupon-inputs>
                                </mat-card-content>
                            </mat-card>
                        </div>
                    </div>
                    <div class="row btn-row" *ngIf="this.productBuildDetails.shape != 2">
                        <button class="col-md-12 btn-with-icon-suffix" mat-raised-button (click)="viewPaymentSchedule()"><mat-icon iconPositionEnd>chevron_right</mat-icon>PAYMENT SCHEDULE</button>
                    </div>
                    <div class="row btn-row" *ngIf="this.productBuildDetails.shape != 2">
                        <button class="col-md-12 btn-with-icon-suffix" mat-raised-button (click)="viewFullSchedule()"><mat-icon iconPositionEnd>chevron_right</mat-icon>FULL SCHEDULE</button>
                    </div>
                    <div class="row" *ngIf="userProfile.isAdmin">
                      <app-admin [currentLoadedProduct]="currentLoadedProduct" (loadProductEmitter)="validateAndLoadProduct($event)"></app-admin>
                    </div>
                </div>
                <div class="col-md-6 inputs-right">
                    <div class="row">
                        <div class="col-md-12">
                            <mat-card>
                                <mat-card-header>Variables & Fees</mat-card-header>
                                  <div class="row">
                                    <div class="col-md-12 put-type chip-options">
                                    <mat-chip-listbox (change)="accountTypeChanged($event)" aria-label="Account Type" class="accountType">
                                      <mat-chip-option class="threshold-option" value="4" [selected]="this.isAccountTypeFee()">FEE</mat-chip-option>
                                      <mat-chip-option class="threshold-option" value="5" [selected]="this.isAccountTypeCommission()">COMMISSION</mat-chip-option>
                                    </mat-chip-listbox>
                                    </div>
                                  </div>
                                <mat-card-content>
                                    <div class="row">
                                        <div class="col-md-6 label-hover">
                                            <mat-label class="label">SOLVE FOR</mat-label>
                                            <mat-form-field class="col-md-12">
                                                <mat-select id="solve-for-selector" [(value)]="productBuildDetails.solveFor" (selectionChange)="solveForUpdate($event)">
                                                    @for (sf of solveForOptions; track sf) {
                                                        <mat-option *ngIf="shouldShowOption(sf.templates)" [value]="sf.value">{{sf.name}}</mat-option>
                                                    }
                                                </mat-select>
                                                <mat-icon matSuffix>expand_more</mat-icon>
                                            </mat-form-field>
                                        </div>
                                        <div class="col-md-6 label-hover">
                                            <mat-label class="label">REOFFER</mat-label>
                                            <mat-form-field class="col-md-12">
                                                <input id="reoffer-input" class="percent-input" onclick="this.select()" matInput [(ngModel)]="productBuildDetails.reoffer">
                                                <span matTextSuffix>%</span>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </mat-card-content>
                            </mat-card>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <mat-card>
                                <mat-card-header>Tenor & {{this.productBuildDetails.shape == 2 ? 'Dates' : 'Callability'}}</mat-card-header>
                                <mat-card-content>
                                    <div class="row">
                                        <div class="col-md-2 label-hover">
                                            <mat-label class="label">TENOR</mat-label>
                                            <mat-form-field floatLabel="always" class="col-md-12">
                                                <input id="tenor-input" #tenorInput matInput [(ngModel)]="tenor" pattern="\b\d+(M|m|y|Y)\b" (change)="tenorChanged()">
                                                <mat-error>"Number then Y or M"</mat-error>
                                            </mat-form-field>
                                        </div>
                                        <div class="col-md-5 label-hover">
                                            <mat-label class="label">STRIKE DATE</mat-label>
                                            <mat-form-field class="col-md-12">
                                                <input matInput [matDatepicker]="picker" [matDatepickerFilter]="holidayDateFilter" [(ngModel)]="productBuildDetails.strikeDate" (dateChange)="strikeDateChanged()">
                                                <!-- <mat-hint>DD/MM/YYYY</mat-hint> -->
                                                <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                                                <mat-datepicker #picker></mat-datepicker>
                                            </mat-form-field>
                                        </div>
                                        <div class="col-md-5 label-hover">
                                            <mat-label class="label">FINAL VALUATION DATE</mat-label>
                                            <mat-form-field class="col-md-12">
                                                <input matInput [matDatepicker]="finalPicker" [matDatepickerFilter]="holidayDateFilter" [(ngModel)]="productBuildDetails.finalValuationDate">
                                                <!-- <mat-hint>DD/MM/YYYY</mat-hint> -->
                                                <mat-datepicker-toggle matIconSuffix [for]="finalPicker"></mat-datepicker-toggle>
                                                <mat-datepicker #finalPicker></mat-datepicker>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <app-autocall-trigger-inputs *ngIf="this.productBuildDetails.shape != 2" [(frequency)]="productBuildDetails.frequency" [(autocallOptions)]="productBuildDetails.autocallOptions" [canChangeCallType]="productBuildDetails.shape == 1" (lockoutChanged)="lockoutUpdated($event)" (somethingChanged)="autoCallTriggerInputsHaveChanged()"></app-autocall-trigger-inputs>
                                    <div class="row content-row date-btn" *ngIf="this.productBuildDetails.shape != 2">
                                        <button class="col-md-12 btn-with-icon-suffix" mat-raised-button (click)="viewDateSchedule()"><mat-icon iconPositionEnd>chevron_right</mat-icon>DATE SCHEDULE</button>
                                    </div>
                                </mat-card-content>
                            </mat-card>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <mat-card>
                                <mat-card-header>Market Participation</mat-card-header>
                                <mat-card-content>
                                    <app-market-participation [templateType]="this.productBuildDetails.shape!" [defaultDownwardCap]="defaultDownwardCap" [participation]="productBuildDetails.marketParticipation" (marketParticipationChanged)="marketParticipationChange($event)"></app-market-participation>
                                </mat-card-content>
                            </mat-card>
                        </div>
                    </div>
                    <div class="row btn-row">
                      <div class="col-md-6 no-pad-left">
                        <button class="col-md-12 calculate-btn" mat-raised-button color="primary" [disabled]="isSendingRequest || this.productBuildDetails.accountType == AccountType.UNKNOWN" (click)="addToPricingQueue()">CALCULATE</button>
                        <div class="row accountTypeWarning" *ngIf="this.productBuildDetails.accountType == AccountType.UNKNOWN">
                          Cannot calculate without choosing fee or commission as account type.
                        </div>
                      </div>
                      <div class="col-md-6 no-pad-right">
                        <button class="col-md-12 border-btn calculate-btn" mat-raised-button (click)="resetProduct()">NEW PRODUCT</button>
                      </div>
                    </div>
                  <div class="row btn-row" *ngIf="this.pastTradeDate()">
                    <div class="col-md-6 no-pad-left">
                      <button class="col-md-12 calculate-btn" mat-raised-button color="primary" [disabled]="isSendingRequest || this.productBuildDetails.accountType == AccountType.UNKNOWN" (click)="addToPricingQueue(true)">Rerun Launch Analytics</button>
                    </div>
                  </div>
                </div>
            </div>
        </div>

        <div class="col-md-4 output-section">
            <div class="row">
                <mat-card class="pricing-queue-card col-md-12">
                    <mat-card-header>Pricing Queue</mat-card-header>
                    <mat-card-content>
                        <app-pricing-queue (showProductBuildDetails)="updateProductBuildDetails($event)" (rowSelected)="rowSelected($event)" [issuers]="issuers"></app-pricing-queue>
                    </mat-card-content>
                </mat-card>
            </div>
            <div class="row">
                <mat-card class="analysis-card col-md-12">
                    <mat-card-header class="analysis-header">
                      Analysis<span class="date-range">{{ updateDataDateRange(currentRowSelected) }}</span>
                    </mat-card-header>
                    <mat-card-content>
                        <app-analysis-view [pricingItem]="currentRowSelected"></app-analysis-view>
                    </mat-card-content>
                </mat-card>
            </div>
            <div class="row" *ngIf="currentRowSelected?.productFees">
                <mat-card class="analysis-card col-md-12">
                    <mat-card-header>Fees</mat-card-header>
                    <mat-card-content>
                        <app-fees-view [productFees]="currentRowSelected.productFees"></app-fees-view>
                    </mat-card-content>
                </mat-card>
            </div>

        </div>
    </div>
</div>

<div class="watermark">
    <img src="/assets/ARROW_Brandmark_Gradient2_GEMGREEN.png">
</div>

