<div class="container full-screen-container">
    <div class="row">
        <h2 class="review-title col-md-3 center-boxes">Welcome to Arrow IA</h2>
    </div>
    <div class="row">
        @for (option of options; track option) {
            <div class="col-md-3 center-boxes">
                <app-option-box [name]="option.name" [isV2]="option.isV2" [path]="option.path" [description]="option.description" [iconPath]="option.iconPath"></app-option-box>
            </div>
        }
    </div>
</div>



