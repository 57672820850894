<div class="row">
    <div class="col-md-12 listbox chip-options">
        <mat-chip-listbox (change)="digitalEnabled($event)" aria-label="Digital">
            <mat-chip-option [selected]="enabled">DIGITAL</mat-chip-option>
        </mat-chip-listbox>
    </div>
</div>
<div class="row" *ngIf="enabled">
    <div class="col-md-6 label-hover">
        <mat-label class="label">Digital Threshold</mat-label>
        <mat-form-field floatLabel="always" class="col-md-12">
            <input onclick="this.select()" id="digital-threshold-input" matInput type="number" class="percent-input" [(ngModel)]="digitalThreshold" (change)="somethingChanged()">
            <span matTextSuffix>%</span>
        </mat-form-field>
    </div>
    <div class="col-md-6 label-hover">
        <mat-label class="label">Digital Amount</mat-label>
        <mat-form-field floatLabel="always" class="col-md-12" *ngIf="!solvingForDigital">
            <input onclick="this.select()" id="digital-amount-input" matInput type="number" [(ngModel)]="digitalAmount" (change)="somethingChanged()">
        </mat-form-field>
        <mat-form-field floatLabel="always" class="col-md-12" *ngIf="solvingForDigital">
            <input matInput placeholder="SOLVING" disabled>
        </mat-form-field>
    </div>
</div>
