<div class="row">
    <div class="col-md-12 put-type chip-options">
        <mat-chip-listbox (change)="couponTypeChange($event)" aria-label="Change Call Type">
            <mat-chip-option id="fixed-coupon-type" value="0" [selected]="couponType==0">FIXED</mat-chip-option>
            <mat-chip-option id="contingent-coupon-type" value="1" [selected]="couponType==1">CONTINGENT</mat-chip-option>
            <mat-chip-option id="contingent-memory-coupon-type" value="2" [selected]="couponType==2">CONTINGENT WITH MEMORY</mat-chip-option>
        </mat-chip-listbox>
    </div>
</div>
<div class="row" *ngIf="couponType == 1 || couponType == 2">
    <div class="col-md-6 label-hover">
        <mat-label class="label">COUPON BARRIER</mat-label>
        <mat-form-field floatLabel="always" class="col-md-12">
            <input id="coupon-barrier-input" onclick="this.select()" matInput type="number" placeholder="100" class="percent-input" [(ngModel)]="barrier" (change)="inputChanged()">
            <span matTextSuffix>%</span>
        </mat-form-field>
    </div>
    <div class="col-md-6 label-hover">
        <mat-label class="label">PAYMENT FREQUENCY</mat-label>
        <mat-form-field class="col-md-12">
            <mat-select id="coupon-payment-frequency-selector" [(ngModel)]="frequency" (selectionChange)="inputChanged()">
                @for (freq of frequencies; track freq) {
                    <mat-option [value]="freq.name">{{freq.name}}</mat-option>
                }
            </mat-select>
            <mat-icon matSuffix>expand_more</mat-icon>
        </mat-form-field>
    </div>
</div>
<div class="row" *ngIf="couponType == 1 || couponType == 2">
    <div class="col-md-12 put-type chip-options">
        <mat-chip-listbox (change)="observationTypeChange($event)" aria-label="Observation Type">
            @for (p of possibleObservations; track p) {
                <mat-chip-option [value]="p.value" [selected]="selectedObservation == p.value">{{p.name}}</mat-chip-option>
            }
        </mat-chip-listbox>
    </div>
</div>

<div class="row">
    <div class="col-md-6 label-hover">
        <mat-label class="label">COUPON (P.A.)</mat-label>
        <mat-form-field floatLabel="always" class="col-md-12" *ngIf="!solvingForCoupon">
            <input id="coupon-input" onclick="this.select()" matInput type="number" placeholder="10" class="percent-input" [(ngModel)]="payoff" (change)="inputChanged()">
            <span matTextSuffix>%</span>
        </mat-form-field>
        <mat-form-field floatLabel="always" class="col-md-12" *ngIf="solvingForCoupon">
            <input matInput placeholder="SOLVING" disabled>
        </mat-form-field>
    </div>
</div>
