<div class="row">
    <div class="col-md-12 listbox chip-options">
        <mat-chip-listbox (change)="uamChanged($event)" aria-label="Growth">
            <mat-chip-option [selected]="hasUpsideAtMaturity">{{isUpward ? 'GROWTH IN UPWARD MARKET' : 'ABSOLUTE RETURN IN DOWNWARD MARKET'}}</mat-chip-option>
        </mat-chip-listbox>
    </div>
</div>
<div class="row" *ngIf="hasUpsideAtMaturity">
    <div class="col-md-6 label-hover">
        <mat-label class="label">Participation {{isUpward ? 'Above' : 'Below'}}</mat-label>
        <mat-form-field floatLabel="always" class="col-md-12" *ngIf="!solvingForAbove">
            <input onclick="this.select()" id="participation-{{isUpward ? 'above' : 'below'}}-input" matInput type="number" class="percent-input" [(ngModel)]="payoffStrike" (change)="inputChanged()">
            <span matTextSuffix>%</span>
        </mat-form-field>
      <mat-form-field floatLabel="always" class="col-md-12" *ngIf="solvingForAbove">
        <input matInput placeholder="SOLVING" disabled>
      </mat-form-field>
    </div>
    <div class="col-md-6 label-hover">
        <mat-label class="label">Participation Rate</mat-label>
        <mat-form-field floatLabel="always" class="col-md-12" *ngIf="!solvingForParticipation">
            <input onclick="this.select()" id="participation-rate-input" matInput type="number" placeholder="100" class="percent-input" [(ngModel)]="participation" (change)="inputChanged()">
            <span matTextSuffix>%</span>
        </mat-form-field>
      <mat-form-field floatLabel="always" class="col-md-12" *ngIf="solvingForParticipation">
        <input matInput placeholder="SOLVING" disabled>
      </mat-form-field>
    </div>
</div>
<div class="row" *ngIf="hasUpsideAtMaturity">
    <div class="col-md-12 listbox chip-options">
        <mat-chip-listbox (change)="cappedChanged($event)" aria-label="Capped">
            <mat-chip-option [selected]="isCapped">CAPPED</mat-chip-option>
        </mat-chip-listbox>
    </div>
</div>
<div class="row" *ngIf="hasUpsideAtMaturity && isCapped">
    <div class="col-md-6 label-hover">
        <mat-label class="label">Cap</mat-label>
        <mat-form-field floatLabel="always" class="col-md-12" *ngIf="!solvingForCap">
            <input onclick="this.select()" id="capped-input" matInput type="number" placeholder="0" class="percent-input" [(ngModel)]="cap" (change)="inputChanged()">
            <span matTextSuffix>%</span>
        </mat-form-field>
        <mat-form-field floatLabel="always" class="col-md-12" *ngIf="solvingForCap">
            <input matInput placeholder="SOLVING" disabled>
        </mat-form-field>
    </div>
    <div class="col-md-6 label-hover">
        <mat-label class="label">Max Return</mat-label>
        <mat-form-field floatLabel="always" class="col-md-12">
            <input matInput type="number" disabled placeholder="100" class="percent-input" [(ngModel)]="maxReturn" format="0.2-2">
            <span matTextSuffix>%</span>
        </mat-form-field>
    </div>
</div>
