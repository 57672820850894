import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../../environments/environment";
import {AdvisorAnalyticsResponse} from "./advisor-analytics.service";

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  constructor(private http: HttpClient) { }

  public forceRunZSpreads() : Observable<void> {
    let url = environment.httpProtocol+environment.backendUrl + '/api/v1/admin/zspread/run'
    return this.http.post<void>(url, { withCredentials: true })
  }

  public forceRunSecondaryAnalysis(): Observable<void> {
    let url = environment.httpProtocol+environment.backendUrl + '/api/v1/admin/secondary/run'
    return this.http.post<void>(url, { withCredentials: true })
  }

  public forceFullRunSecondaryAnalysis(): Observable<void> {
    let url = environment.httpProtocol+environment.backendUrl + '/api/v1/admin/secondary/full/run'
    return this.http.post<void>(url, { withCredentials: true })
  }

  public runCalendar(recepients: string[]): Observable<void> {
    let url = environment.httpProtocol+environment.backendUrl + '/api/v1/admin/calendar'
    return this.http.post<void>(url, { recipients: recepients }, { withCredentials: true })
  }
}
